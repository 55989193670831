import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { uploadImageWithCompress } from "@/api/user.js";
/**
 * 查询资产接口 query 转 底层接口需要的 conditions
 * @param {*} param
 * @return conditions
 * @return result eg: result["BSV"]["WEB"]["2020-11-12"] = {}
 * @return items eg [{ day: "2020" }]
 */
export const query2conditions = ({ start_date, end_date, coin, product }) => {
  const { dateList, coinList, productList } = handleQuery({
    start_date,
    end_date,
    coin,
    product,
  });

  // 构造查询 condition，三层循环 哈哈哈
  const conditions = [];
  let result = {};
  const items = [];

  coinList.forEach((coin) => {
    productList.forEach((product) => {
      dateList.forEach((date) => {
        // 构造参数
        const coinType = coin.toUpperCase();
        const productType = product.toUpperCase();
        // 生成 condition
        const amountCondition = {
          io_type: 0,
          coin_type: coinType,
          product_type: productType,
          end: date.unix,
        };
        conditions.push(amountCondition);
        const incomeCondition = {
          io_type: 1,
          coin_type: coinType,
          product_type: productType,
          end: date.unix,
        };
        conditions.push(incomeCondition);
        const outcomeCondition = {
          io_type: 2,
          coin_type: coinType,
          product_type: productType,
          end: date.unix,
        };
        conditions.push(outcomeCondition);
        items.push({ day: date.value, product, coin });
        // 生成 result
        result[coinType] = result[coinType] || {};
        result[coinType][productType] = result[coinType][productType] || {};
        result[coinType][productType][date.value] = {
          amount: 0,
          income: 0,
          outcome: 0,
          conditions: [amountCondition, incomeCondition, outcomeCondition],
        };
      });
    });
  });

  return {
    conditions,
    result,
    items,
  };
};

export const handlesResponse = (response, items, rate) => {
  const list = (response && response.data && response.data.amounts) || [];

  return items.map((item, index) => {
    const amount = list[index * 3];
    const income = list[index * 3 + 1];
    const outcome = list[index * 3 + 2];
    const amount_coin = amount2Coin(amount, item.coin);
    const income_coin = amount2Coin(income, item.coin);
    const outcome_coin = amount2Coin(outcome, item.coin);
    const currentRate = rate[item.coin] || 0;

    return {
      ...item,
      amount,
      amount_coin,
      amount_cny: (currentRate * amount_coin).toFixed(2),
      income,
      income_coin,
      income_cny: (currentRate * income_coin).toFixed(2),
      outcome,
      outcome_coin,
      outcome_cny: (currentRate * outcome_coin).toFixed(2),
    };
  });
};

export const amount2Coin = (amount, coinType) => {
  if (coinType.toUpperCase() === "ETH") {
    return +gwei2coin(amount);
  } else {
    return +satoshi2coin(amount);
  }
};

const satoshi2coin = (value) => {
  const amount = +value;

  if (!amount) {
    return 0;
  }

  if (amount === 0) {
    return amount.toString();
  } else {
    return parseFloat((1e-8 * amount).toFixed(8));
  }
};

const gwei2coin = (value) => {
  const amount = +value;
  if (!amount) {
    return 0;
  }

  if (amount === 0) {
    return amount.toString();
  } else {
    return parseFloat((1e-9 * amount).toFixed(9));
  }
};

export const amount2Cny = (amount, coinType) => {};

const handleQuery = ({ start_date, end_date, coin, product }) => {
  // 查询特定天的 23:59:59 的时间戳
  const end = dayjs(end_date);
  const dateLength = end.diff(start_date, "days") + 1;
  const dateList = new Array(dateLength).fill().map((_, index) => {
    const current = end.subtract(index, "day");
    return {
      // 时间戳
      unix: current.add(1, "day").unix() - 1,
      // 格式化
      value: current.format("YYYY-MM-DD"),
    };
  });
  // 构造币种列表
  const coinList = coin ? [coin] : ["BSV", "BTC", "ETH"];
  // 构造产品列表
  const productList = product ? [product] : ["ALL", "WEB", "APP"];

  return {
    dateList,
    coinList,
    productList,
  };
};

export const StartTime = (timestamp) => {
  return (
    dayjs(timestamp)
      .startOf("day")
      .unix() ||
    dayjs()
      .startOf("day")
      .unix()
  );
};

export const EndTime = (timestamp) => {
  return (
    dayjs(timestamp)
      .endOf("day")
      .unix() ||
    dayjs()
      .endOf("day")
      .unix()
  );
};

export const UtcTimeStampFormat = (timestamp) => {
  dayjs.extend(utc);
  return dayjs.utc(timestamp).format("YYYY-MM-DD HH:mm:ss");
};

export const TimeStampFormat = (timestamp) => {
  return dayjs(timestamp).format("YYYY-MM-DD");
};

export const VersionDetection = {
  "-1": "每次登陆",
  1: "1天",
  3: "3天",
  7: "一周",
  14: "两周",
};

// 版本管理
export const VersionUpgrade = {
  force: "强制升级",
  prompt: "提示升级",
};

export const VersionApp = {
  wallet_pro: "钱包pro",
  nft: "nft项目",
  nft_shop: "光萃",
  interstellar: "星界",
};

// 资金流水
export const PlatformType = {
  "": "全部",
  3: "恒境",
  2: "矿池业务",
  1: "开放平台",
};

export const FinancialType = {
  "": "全部",
  1: "正常业务",
  2: "退款",
};

export const OptionType = {
  "": "全部",
  3: "提现",
  1: "nft交易",
  4: "nft铸造",
  2: "代收法币",
};

export const BalanceType = {
  "": "全部",
  1: "收入",
  2: "支出",
};

export const ChannelType = {
  "": "全部",
  1: "微信",
  2: "支付宝",
  3: "银行",
};

export const transformTime = (timestamp) => {
  const dayjs = require("dayjs");
  const utc = require("dayjs/plugin/utc");
  dayjs.extend(utc);
  return dayjs.utc(timestamp).format("YYYY/MM/DD HH:mm:ss");
};

export const DistributionType = {
  0: "全部",
  1: "普通用户",
  2: "应用",
  3: "开发者",
};

export const PlanCreatorType = {
  0: "全部",
  1: "平台",
  2: "开发者",
};

export const CoinMap = [
  {
    value: "CNY",
    label: "CNY",
  },
  {
    value: "USD",
    label: "USD",
  },
  {
    value: "EUR",
    label: "EUR",
  },
  {
    value: "GBP",
    label: "GBP",
  },
  {
    value: "BSV",
    label: "BSV",
  },
  {
    value: "ETH",
    label: "ETH",
  },
  {
    value: "BTC",
    label: "BTC",
  },
];

export const AppRewardType = ["", "消费返佣", "注册固定奖励", "消费固定奖励"];

export const InviteUserType = ["", "平台新用户", "应用新用户"];

export const ProjectType = {
  0: "设计",
  1: "科技",
  2: "模型",
  3: "出版",
  4: "其他",
};

export const ProjectStage = {
  0: "未开始",
  1: "众筹中",
  2: "众筹成功",
  3: "众筹失败",
  4: "项目结束",
};

export const ApprovalStatus = {
  0: "未审核",
  1: "审核中",
  2: "已发布",
};

export const uploadImage = async (file) => {
  try {
    const formData = new FormData();
    formData.append("file", file);
    const { data } = await uploadImageWithCompress(formData);
    return data.data.original;
  } catch (error) {
    throw error;
  }
};
export const UserTagMap = [
  {
    value: "none",
    label: "不指定",
  },
  {
    value: "social_new_user",
    label: "星笺新用户",
  },
];

export const PlatformTagMap = [
  {
    value: "none",
    label: "不指定",
  },
  {
    value: "official_planet",
    label: "官方星球",
  },
];
