<template>
  <div>
    <el-form
      :model="queryForm"
      :inline="true"
      class="demo-form-inline"
      style="padding: 20px 20px 0"
    >
      <el-form-item label="ucid">
        <el-input v-model="queryForm.ucid" placeholder="请输入" />
      </el-form-item>

      <el-form-item label="订单id">
        <el-input v-model="queryForm.order_id" placeholder="请输入" />
      </el-form-item>

      <el-form-item label="第三方订单id">
        <el-input v-model="queryForm.third_order_id" placeholder="请输入" />
      </el-form-item>

      <el-form-item>
        <el-button type="primary" @click="onQuery">查询</el-button>
        <el-button type="info" @click="onClear">重置</el-button>
      </el-form-item>
    </el-form>

    <div style="padding: 0 20px;">
      <el-table :data="data" border stripe>
        <el-table-column
          v-for="col in columns"
          :prop="col.id"
          :key="col.id"
          :label="col.label"
          :width="col.width"
          :sortable="col.sortable"
          :formatter="col.formatter"
        />
      </el-table>
    </div>
  </div>
</template>

<script>
import { getRefundOrderStatus } from "../../../../api/nft";
import dayjs from "dayjs";
import { StartTime, EndTime } from "@/utils/helper.js";

export default {
  data() {
    return {
      queryForm: {},
      data: [],
      columns: [
        {
          id: "ucid",
          label: "ucid",
        },
        {
          id: "order_id",
          label: "订单id",
        },
        {
          id: "buy_info_id",
          label: "交易号",
        },
        {
          id: "third_order_id",
          label: "第三方订单id",
        },

        {
          id: "pay_time",
          label: "付款时间",
          formatter: (row) =>
            +row.pay_time > 0 ? dayjs(+row.pay_time * 1000).format("YYYY-MM-DD HH:mm:ss") : "-",
        },

        {
          id: "buy_status",
          label: "是否购买成功",
        },
        {
          id: "refund_status",
          label: "是否退款",
        },
        {
          id: "refund_time",
          label: "退款时间",
          formatter: (row) =>
            +row.refund_time > 0
              ? dayjs(+row.refund_time * 1000).format("YYYY-MM-DD HH:mm:ss")
              : "-",
        },
      ],
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      try {
        const { data } = await getRefundOrderStatus(this.queryForm);
        this.data = [...data.data];
      } catch (error) {
        console.log(error);
      }
    },
    onQuery() {
      this.fetchData();
    },
    onClear() {
      this.queryForm = {};
      this.fetchData();
    },
  },
};
</script>

<style lang="less">
.number-input.el-input-number .el-input__inner {
  text-align: left;
}
</style>
